@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.choices-tab-navigation {
  display: flex;
  justify-content: space-between;

  &__pension-options {
    display: flex;
    flex-wrap: nowrap;
  }

  .nav-button {
    border: none;
    border-bottom: 7.5px solid white;
    font-size: 14px;
    padding: 15px 20px 7.5px;
    cursor: pointer;
    color: $brand-dark-blue;
    display: inline-flex;

    &--change-tab {
      background: #ffffff;

      &:hover,
      &.selected {
        background: $brand-dark-grey;
        border-bottom: 7.5px solid $brand-dark-grey;
      }

      &.selected {
        text-shadow: 0.7px 0 0 currentColor;
      }
    }

    &--reset {
      align-items: center;
      background-color: #ffffff;
      padding: 10px;

      &::after {
        content: '';
        background-image: url('../../../images/reset_icon.svg');
        background-repeat: no-repeat;
        position: relative;
        width: 20px;
        height: 17px;
        margin-left: 9px;
        background-size: contain;
      }

      &:hover {
        background-color: $brand-light-grey;
      }

      &:active {
        background-color: $brand-dark-grey;
      }
    }

    &--compare-lta {
      margin-left: 9px;
      background-color: $brand-light-gold;
      font-weight: 600;
      padding: 10px;

      &::after {
        content: '';
        background-image: url('../../../images/compare_icon.svg');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        min-width: 19px;
        min-height: 16px;
        margin: auto 8px;
      }

      &:active {
        background-color: $brand-light-gold;
      }

      &.selected {
        background-color: $brand-x-light-gold;
        border-bottom: 7.5px solid $brand-x-light-gold;
      }
    }
  }

  @media (max-width: $tablet-portrait) {
    align-items: flex-end;

    &__reset-or-compare {
      flex-direction: column;
      display: flex;
      position: relative;
    }

    .nav-button {
      font-size: 12px;
      padding: 10px 6px;
      border-width: 2px;

      &--change-tab {
        &:hover,
        &.selected {
          border-bottom: 2px solid $brand-dark-grey;
        }
      }

      &--reset {
        align-self: flex-end;
        position: absolute;
        top: -45px;
      }

      &--compare-lta {
        text-align: left;
        font-weight: 400;

        &::after {
          min-width: 13.75px;
          min-height: 11px;
        }

        &:hover,
        &.selected {
          border-bottom: 2px solid $brand-x-light-gold;
        }
      }
    }
  }
}

.user-options-tab-content {
  padding: 30px;
  background-color: $brand-dark-grey;

  form {
    display: flex;
    flex-direction: column;
    gap: 39px;
  }

  .show-more-label {
    max-width: 307px;
  }

  h2,
  h3 {
    font-size: 16px;
  }

  h2,
  h3 {
    margin: 0 0 22px;
  }

  &--compare-lta {
    background-color: $brand-x-light-gold;

    p + h3 {
      margin-top: 22px;
    }

    h2 {
      font-size: 24px;
    }

    .slider-container {
      input[type='number'],
      input[type='text'] {
        width: 93px;
      }
    }
  }

  &__future {
    background-color: $brand-light-grey;

    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  @media (max-width: $tablet-portrait) {
    padding: 20px;
  }
}

.chart-lta {
  display: flex;
  flex-wrap: wrap;

  &__column {
    flex: 49%;
    text-align: center;
    &:first-child {
      flex: 49%;
    }
  }
  &--lta {
    padding-right: 11%;
  }
}

.chart-footer {
  display: flex;
  flex-wrap: wrap;
  padding-left: 14%;
  padding-right: 5%;

  &__column {
    flex: 20%;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    text-align: center;
  }
  &--lta {
    padding-left: 10%;
    padding-right: 7%;
  }
}

.results-section {
  h2 {
    margin-bottom: 14px;
  }
  p {
    margin-top: 6px;
  }

  @media (max-width: $mobile-wide) {
    h2 {
      margin: 10px auto 20px;
    }

    p {
      margin: 0 auto 16px;
    }
  }
}
