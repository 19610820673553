@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.money-input-wrapper {
  align-self: flex-start;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }   

  &__timeframe {
    font-size: 14px;
    line-height: 22px;
    margin-left: 20px;
  }

  @media (max-width: $tablet-portrait) {
    &__timeframe {
      font-size: 14px;
      line-height: 22px;
      margin-left: 10px;
    }
  }
}
