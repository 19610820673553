@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.information-box {
  background-color: $brand-dark-grey;
  margin: 30px 0;
  padding: 22px 22px 30px;
  display: flex;
  flex-direction: row;

  &__icon {
    margin-right: 22px;
    height: 26px;
    width: 26px;
  }

  @media (max-width: $tablet-portrait) {
    &__icon {
      display: none;
    }
  }

  @media (max-width: $mobile-wide) {
    flex-direction: column;
  }
}
