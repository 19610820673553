@import '../../css/breakpoints.scss';

.bar-chart-wrapper {
  margin: 50px auto 0;
  max-width: 900px;

  @media (max-width: $tablet-landscape) {
    margin: 12px auto 0;
  }
}

.highchart-lta-y-axis {
  fill: #7cb5ec;
  stroke: #7cb5ec;
  width: 200px;
}
