@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.welcome-page-content {
  .intro-text {
    font-size: 18px;
    line-height: 28px;
  }

  .information-box {
    &__heading {
      margin: 0 0 20px 0;
    }

    label {
      color: #4f4f4f;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      align-items: center;
      font-family: Arial;
    }

    .checkmark-container {
      position: relative;
      display: flex;

      input {
        margin: 0 12px 0 0;
        position: absolute;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: relative;
        margin-right: 12px;
        top: 0;
        left: 0;
        min-height: 24px;
        min-width: 24px;
        background-color: white;
        border: 2px solid black;
        border-radius: 3px;
      }

      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
        left: 9px;
        top: 2px;
        width: 5px;
        height: 14px;
        border: solid $brand-aqua;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .checkmark--visible:after {
        display: block;
      }
    }

    .button--page-navigation {
      margin: 0 0 0 auto;
    }

    .button--open-terms {
      background: none;
      padding: 0;
      border: none;
      font-size: 14px;
      line-height: 18px;
      text-decoration: underline;
      cursor: pointer;
    }

    @media (max-width: $mobile-wide) {
      .button--page-navigation {
        margin-top: 20px;
      }
    }
  }

  .terms-and-conditions-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s;

    h2 {
      margin-top: 0;
    }

    &--visible {
      max-height: 1500px;
    }
  }
}
