@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.your-details-page-content {
  .information-box {
    justify-content: flex-start;
    padding: 16px;
    align-items: center;
    margin: 8px 0;

    p {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }

    a {
      font-weight: normal;
      color: black;
    }
  }
}

.user-details-form {
  display: flex;
  flex-direction: column;
  gap: 38px;
  
  .radio-option,
  .money-input,
  input {
    align-self: flex-start;
  }
}

@media (max-width: $tablet-landscape) {
  .your-details-page-content .information-box p {
    font-size: 14px;
  }
}