@import '../../css/breakpoints.scss';

.page-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: $tablet-portrait) {
    padding: 0 10px;
  }
}
