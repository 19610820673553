@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.company-logos {
  display: flex;
  flex-direction: row;
  padding: 4px 20px;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  &__xps {
    height: 52px;
    width: 159px;
  }

  &__bank-of-england {
    width: 229px;
    height: 30px;
  }

  @media (max-width: $tablet-portrait) {
    padding: 4px 10px;

    &__bank-of-england {
      width: 190px;
      height: 25px;
    }
  }

  @media (max-width: $mobile-wide) {
    &__xps {
      height: 33px;
      width: 103px;
    }

    &__bank-of-england {
      width: 132px;
      height: 17px;
    }
  }
}
