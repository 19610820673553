.fade-enter {
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 0 ease-out;
  position: absolute;
}

.fade--fast-enter {
  opacity: 0;
}
.fade--fast-enter.fade--fast-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}
.fade--fast-exit.fade--fast-exit-active {
  opacity: 0;
  transition: opacity 0 ease-out;
  position: absolute;
}
