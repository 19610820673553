@import '../../css/colors.scss';
@import '../../css/breakpoints.scss';

.help-label {
  display: flex;
  color: $brand-dark-blue;
  max-width: 367px;
  width: 100%;
  font-size: 14px;
  line-height: 1.3rem;
  font-weight: 400;
  .column {
    flex-direction: column;
  }

  &__primary-description {
    display: inline-block;
    max-width: 320px;
    font-weight: 700;
  }

}

.help-popup {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 0px;
  background-image: url('../../images/help-icon.svg');
  background-size: cover;
  line-height: 1.3rem;
  font-weight: 400;
  z-index: 101;
  text-align: left;
  &:hover {
    background-image: url('../../images/help-icon-over.svg');
    transition: all 0.3s;
    cursor: pointer;
  }

  &__blank {
    background: none;
    &:hover {
      cursor: auto;
      background: none;
    }
  }

  &--salary-increase {
    width: auto;
  }
}

