@import '../../../../css/colors.scss';
@import '../../../../css/breakpoints.scss';

.annual-allowance-table {
  table {
    text-align: left;
    width: 100%;
  }

  thead th {
    padding-left: 0;
    background-color: #ffffff;
  }

  td,
  th {
    padding: 7px 5px;
    background-color: $brand-light-grey;

    &.current-user-accrual-rate {
      background-color: $brand-teal;
    }
  }

  .important-row {
    td,
    th {
      background-color: $brand-dark-grey;

      &.current-user-accrual-rate {
        background-color: $brand-mid-teal;
      }
    }
  }

  input#dc-contributions-during-tax-year {
    max-width: 54px;
    height: 12px;
    text-align: center;
  }

  .row-heading {
    flex-shrink: 0;
    width: 290px;
  }

  .adjust-assumptions-wrapper-cell {
    width: 68px;
    padding: 0;
    position: relative;
  }

  .adjust-assumptions-sidebar {
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 68px;

    .show-more-label__primary-description {
      font-weight: 400;
      font-size: 14px;
    }

    &__heading {
      background: #88939f;

      display: flex;
      align-items: flex-start;
      justify-content: center;
      border: none;
      cursor: pointer;
      transition: max-width 0.2s;
      margin: 0;
      width: 68px;


      span {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        font-size: 16px;
        color: #ffffff;
        font-weight: 700;
        margin: 200px 0 0 0;
        text-align: center;
        height: 200px;


      }

      &::after {
        content: '';
        height: 21px;
        background-image: url('../../../../images/accordian_arrow_white_left.svg');
        position: absolute;
        top: 20px;
        width: 48px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &.active {
        width: 48px;
        &::after {
          background-image: url('../../../../images/accordian_arrow_white_right.svg');
        }
      }
    }

    &__inputs {

      background: #e7e9ec;
      max-width: 0;
      width: 100%;
      transition: max-width 0.2s;
      overflow: hidden;
      gap: 24px;
      padding: 24px 0;

      &.active {
        max-width: 274px;
      }

      .input-fieldset {
        display: block;
        margin: 0 17px;
      }

      .show-more-label {
        margin-bottom: 19px;
      }

      .slider-container {
        margin-bottom: 39px;
      }
    }
  }

  @media (max-width: $tablet-portrait) {
    table {
      font-size: 14px;
    }

    .row-heading {
      width: 150px;
    }

    .adjust-assumptions-wrapper-cell {
      width: 38px;
    }

    .adjust-assumptions-sidebar {
      min-width: auto;
      &__heading {
        max-width: 38px;
      }
    }
  }

  @media (max-width: $tablet-portrait) {
    table {
      td {
        font-size: 12px;
      }

      input#dc-contributions-during-tax-year {
        max-width: 36px;
        width: 100%;
        padding: 4px 6px;
        height: 12px;
        text-align: center;
        font-size: 12px;
      }

      .row-heading {
        width: 120px;
        .show-button {
          display: none;
        }
      }

      .show-more-label {
        font-size: 12px;
      }

      .show-button {
        font-size: inherit;
      }
    }
  }
}
