@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.slider-container {
  display: flex;
  align-self: flex-start;

  input[type='range'] {
    -webkit-appearance: none;
    height: 34px;
    width: 256px;
    border: 1px solid $brand-dark-blue;
    border-right: none;
    border-left: none;
    margin: 0;
    overflow: hidden;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 32px;
      height: 34px;
      background: #77e3e4;
      cursor: pointer;
      background-image: url('../../../images/slider_icon.svg');
      background-repeat: no-repeat;
      background-size: 10px 15px;
      background-position: center;
      box-shadow: -320px 0 0 320px #e7e9ec;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 32px;
      height: 34px;
      background: #77e3e4;
      cursor: pointer;
      background-image: url('../../../images/slider_icon.svg');
      background-repeat: no-repeat;
      background-size: 10px 15px;
      background-position: center;
      box-shadow: -320px 0 0 320px #e7e9ec;
    }

    input[type='range']::-moz-range-progress {
      background-color: #666;
    }
    input[type='range']::-moz-range-track {
      background-color: #ffffff;
    }
    input[type='range']::-ms-fill-lower {
      background-color: #666;
    }
    input[type='range']::-ms-fill-upper {
      background-color: #ffffff;
    }
  }

  input[type='number'],
  input[type='text'] {
    width: 58px;
    height: 36px;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid;
    margin-left: 26px;
    text-align: center;
    padding: 7px 8px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__subtract-button {
    background-image: url('../../../images/minus_icon.svg');
    border-radius: 3px 0 0 3px;
  }

  &__plus-button {
    background-image: url('../../../images/plus_icon.svg');
    border-radius: 0 3px 3px 0;
  }

  &__subtract-button,
  &__plus-button {
    width: 32px;
    height: 36px;
    background-color: #e7e9ec;
    border: 1px solid;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__unit-label {
    margin: auto 0 auto 8px;
    display: inline;
  }

  @media (max-width: $tablet-landscape) {
    input[type='range'],
    input[type='number'],
    input[type='text'] {
      height: 44px;
    }

    input[type='range'] {
      width: 415px;

      &::-webkit-slider-thumb {
        height: 44px;
        width: 40px;
      }
    }

    input[type='number'],
    input[type='text'] {
      width: 75px;
    }

    &__subtract-button,
    &__plus-button {
      height: 46px;
      width: 36px;
    }
  }

  @mixin mobileStyling {
    input[type='range'] {
      display: none;
    }

    button {
      background-color: $brand-teal;
      height: 44px;
    }

    .slider-container__subtract-button {
      order: 0;
      border-right: none;
    }

    input[type='number'],
    input[type='text'] {
      order: 1;
      margin: 0;
      width: 125px;
      height: 44px;
      border-radius: 0;
    }

    .slider-container__plus-button {
      order: 2;
      border-left: none;
    }

    .slider-container__unit-label {
      order: 4;
    }
  }

  @media (max-width: $tablet-portrait) {
    @include mobileStyling;
  }

  &--mobile-styled-by-default {
    @include mobileStyling;
  }
}
