@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.change-inputs-section {
  display: flex;
  flex-direction: row;
  padding: 18px 0 4px 0;
  gap: 40px;

  &__details {
    flex-basis: 46%;
  }

  &__inputs {
    flex-basis: 54%;
  }

  h2 {
    margin: 0 auto 25px;
    font-size: 16px;
  }

  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
    font-size: 14px;
    margin-bottom: 19px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  &__inputs {
    li {
      align-items: center;
      background-color: #ffffff;
      padding: 5px 10px;
      margin-bottom: 5px;
      justify-content: space-between;
    }

    .money-input-wrapper,
    .percentage-input-wrapper {
      flex: 0;
      input {
        width: 69px;
        padding: 3px 6px;
        font-size: 14px;
        height: auto;
      }
    }
  }

  @media (max-width: $tablet-portrait) {
    flex-direction: column;
    gap: 20px;
  }
}

.select-accrual-rates-section {
  margin: 45px 0;

  @media (max-width: $mobile-wide) {
    .show-more-label__primary-description {
      font-size: 14px;
    }
  }
}

.disclaimer {
  max-width: 890px;
}
