@import './colors.scss';
@import './typography/typography.scss';
@import './breakpoints.scss';

html {
  overflow: scroll;
}

.button {
  padding: 20px 24px;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.015em;
  text-decoration: none;
  height: min-content;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  &--page-navigation {
    background-color: $brand-light-gold;
    color: $brand-dark-blue;
    gap: 10px;
    display: flex;
    align-items: center;
    max-width: 49px;
    margin: 0 auto;

    &:hover,
    &:active {
      background-color: $brand-gold;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 5px;
      height: 10px;
      background-image: url('../images/next-page-icon.svg');
      background-size: cover;
    }

    &.disabled {
      background-color: $brand-stone;
    }
  }
}

.page-navigation-buttons {
  margin-top: 60px;
  border-top: 1px solid #d7d7d7;
  text-align: center;
  padding: 35px 0;
}

.input-fieldset {
  padding: 0;
  border: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  @media (max-width: $tablet-landscape) {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }
}

input[type='date'], input[type='text'], input[type='number'] {
  height: 26px;
  width: 153px;
  font-size: 14px;
  font-family: 'Arial';
  color: $brand-dark-blue;
  padding: 8px 10px;
  border: 1px solid $brand-dark-blue;
  border-radius: 3px;
  &.errored {
    border: 1px solid red;
    background: #ffe8e8;
  }
}

.input-validation-errors {
  position: absolute;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  color: red;
  list-style: none;
  padding: 0;
  margin: 6px 0;
}

.placeholder {
  padding: 4px;
  border: 2px solid #FC0FC0;
}
