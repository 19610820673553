@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.navigation-wrapper {
  background-color: $brand-light-grey;
  border-top: 1px solid $brand-stone;

  nav {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  .nav-link {
    width: 190px;
    padding: 15px 10px;
    border-bottom: 4px solid $brand-light-grey;
    cursor: pointer;
    text-decoration: none;
    color: #12273F;
    font-size: 14px;
    line-height: 14px;

    &--selected {
      background-color: $brand-dark-grey;
      border-color:$brand-gold
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }

    &:hover:not(&--selected) {
      background-color: $brand-dark-grey;
      border-color: $brand-dark-grey;
    }
  }


  @media (max-width: $tablet-portrait) {
    .nav-link {
      display: none;

      &--selected, &--prev-page, &--next-page {
        display: inline-flex;
        align-items: center;
      }

      &--selected {
        justify-content: center;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
      }

      &--prev-page, &--next-page {
        font-size: 12px;
        padding: 9px;
        min-width: 80px;
      }

      &--prev-page {
        justify-content: flex-start;
        text-align: left;
        &::before {
          content: '';
          min-width: 12px;
          min-height: 12px;
          margin-right: 6px;
          background-image: url('../../../images/previous_page_icon.svg');
        }
      }

      &--next-page {
        justify-content: flex-end;
        text-align: right;
        &::after {
          content: '';
          min-width: 12px;
          min-height: 12px;
          margin-left: 6px;
          background-image: url('../../../images/next_page_icon.svg');
        }
      }
    }
  }

  @media (max-width: $mobile) {
    .nav-link {
      &--selected {
        font-size: 14px;
      }
    }
  }
}
