@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.further-information-page-content {
  .information-tiles {
    display: flex;
    flex-direction: row;
    gap: 40px;
    max-width: 870px;
    margin-top: 30px;

    &__icon {
      height: 52px;
      width: 52px;
      background-repeat: no-repeat;
      background-position: center;

      &--laptop {
        background-image: url('../../../images/laptop-icon.svg');
      }
      &--piggybank {
        background-image: url('../../../images/piggybank-icon.svg');
      }
      &--phone {
        background-image: url('../../../images/phone-icon.svg');
      }
    }

    &__tile-wrapper {
      flex: 1;
      padding: 13px 20px;
      border-left: 1px solid $brand-stone;
    }

    &__text {
      margin: 17px 0 0;
      font-size: 14px;
      line-height: 22px;
    }

    @media (max-width: $tablet-portrait) {
      gap: 20px;
    }

    @media (max-width: $mobile-wide) {
      flex-direction: column;
    }
  }
}
