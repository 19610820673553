$brand-x-light-gold: #F4EBCD;
$brand-light-gold: #E9D79B;
$brand-gold: #D4AF37;
$brand-dark-blue: #12273F;
$brand-light-grey: #F6F6F6;
$brand-dark-grey: #E7E9EC;
$brand-stone: #C4C9CF;
$brand-teal: #A7EDEE;
$brand-mid-teal: #77E3E4;
$brand-aqua: #34BCC1;
