@import '../breakpoints.scss';

@font-face {
  font-family: "Century Gothic";
  src: url("Century-Gothic.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
}

body {
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
}

h1,
h2 {
  color: $brand-dark-blue;
  font-family: 'Century Gothic';
  margin: 30px 0;
  font-weight: 700;

  @media (max-width: $mobile-wide) {
    font-size: 24px;
    line-height: 30px;
    margin: 30px 0 10px 0;
  }
}

h1 {
  font-size: 32px;
  line-height: 39px;

  @media (max-width: $mobile-wide) {
    font-size: 24px;
    line-height: 30px;
  }
}

h2 {
  font-size: 24px;
  line-height: 29px;

  @media (max-width: $mobile-wide) {
    font-size: 20px;
    line-height: 26px;
  }
}

p, li {
  font-size: 14px;
  line-height: 22px;
}

.copy-section {
  h1,
  h2,
  h3,
  p,
  .information-box {
    max-width: 700px;
  }
}
