@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.radio-description {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  display: inline-block;
}

.radio-description--extended {
  margin-top: 10px;
  background-color: $brand-teal;
  padding: 0 10px;
  max-width: 327px;
  margin-right: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s;

  &.visible {
    max-height: 200px;
  }

  p {
    margin: 10px 0;
  }
}

.radio-options {
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 14px 29px;
}

.radio-option {
  position: relative;
  display: inline-block;
  min-width: 74px;

  input {
    display: none;
  }

  label {
    cursor: pointer;
    font-size: 14px;
    color: $brand-dark-blue;
  }

  label::before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 10px 0 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid #415265;
    background-color: transparent;
  }

  input:checked + label::after {
    border-radius: 11px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 10px;
    left: 5px;
    content: " ";
    display: block;
    background: #3CD7D9;
  }
}

@media (max-width: $tablet-landscape) {
  .radio-option {
    label {
      font-size: 12px;
      &::before {
        width: 22px;
        height: 22px;
        top: 8px;
      }
    }

    input:checked + label::after {
      border-radius: 100%;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 14px;
      left: 6px;
      content: " ";
      display: block;
      background: #3CD7D9;
    }
  }
}

@media (max-width: $mobile-wide) {
  .radio-options {
    flex-direction: column;
  }
}