@import '../../../css/colors.scss';
@import '../../../css/breakpoints.scss';

.accordion-heading {
  width: 100%;
  padding: 22px 60px 20px 20px;
  text-align: left;
  border: none;
  border-top: 1px solid $brand-stone;
  border-bottom: 1px solid $brand-stone;
  background-color: $brand-dark-grey;
  position: relative;
  cursor: pointer;
  margin-top: 32px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;

  &::after {
    content: '';
    width: 16px;
    height: 10px;
    background-image: url('../../../images/accordion_arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    background-size: cover;
    transition: transform 0.1s;
  }

  &:hover, &.active {
    background-color: $brand-light-gold;
  }

  &.active::after {
    transform: translateY(-50%) rotate(0);
  }

  @media (max-width: $mobile-wide) {
    padding: 11px 50px 11px 11px;

    &::after {
      width: 12px;
      height: 8px;
    }

    h2 {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.accordion-content {
  background-color: $brand-light-grey;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;

  &__inner-wrapper {
    margin: 0 auto;
    padding: 0 20px;
  }

  &__text-wrapper {
    margin: 0 auto;
    padding: 10px 0;
  }

  &__close-button {
    span { text-decoration: underline; }
    margin: 0 auto 10px;
    background: none;
    border: none;
    display: block;
    width: 200px;
    padding: 20px;
    cursor: pointer;
    color: black;
    font-size: 14px;
    line-height: 22px;
  }

  &.active {
    max-height: 2000px;
  }

  @media (max-width: $tablet-landscape) {
    &__text-wrapper {
      padding: 10px 20px;
    }
  }
}
